<template>
    <div class="wrapZoneAnalysis">
        <header class="Header-Shopingmall">
            <span class="TitlePage">
                Zone Analysis
            </span>
            <div class="avatar">
                <InfoUserDialog/>
            </div>
        </header>
        <!-- End Header -->
        <section class="TimeSelect mt-40" v-if="listGroup.length">
            <div class="Wraptime">
                <span class="titleTime">
                    {{ $t("reportShopingMall.selectTime") }}
                </span>
                <TimeSelectShopingMall v-on:changeDateRange="getSelectedDateRange"/>
                <div
                    @click="clickChoosingFloor"
                    v-click-outside="clickChoosingOutFloor"
                    class="custom-button-floor ml-16"
                >
                    {{ selectedGroup["name"] }}
                    <span class="material-icons"> arrow_drop_down </span>
                    <div ref="wrapOption" class="wrapOption">
                        <div
                            class="Option"
                            v-for="group in listGroup"
                            :key="group.id"
                            :value="group.id"
                            :class="[group.id === selectedGroup['id'] ? 'selected' : '']"
                            @click.stop="handleSelectGroup(group.id)"
                        >
                            {{ group.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="ExportReport">
                <span class="material-icons mr-8"> file_download </span>
                {{ $t("reportShopingMall.DownloadReport") }}
            </div>
        </section>
        <!-- End Time Select -->
        <!-- Heatmap layout -->
        <section class="heatmapLayout mt-40">
            <div class="wrapHeaderHeatmap">
                <span class="titleHeatmap">
                {{ $t("menu.Heatmap") }}
                </span>
                <div class="heatmap-select-container">
                    <div
                        class="custom-button"
                        @click="clickChoosingLayout"
                        v-click-outside="clickChoosingOutLayout"
                        v-show="selectedHeatmapType.type==='heatmap-layout' && listLayout.length > 0"
                    >
                        {{ selectedLayout['name'] }}
                        <span class="material-icons"> arrow_drop_down </span>
                        <div ref="wrapOptionLayout" class="wrapOption">
                            <div
                                class="Option"
                                v-for="layout in listLayout"
                                :key="layout['id']"
                                :value="layout['id']"
                                :class="[layout.id === selectedLayout['id'] ? 'selected' : '']"
                                @click.stop="handleSelectLayout(layout['id'])"
                            >
                                {{ layout['name'] }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="custom-button"
                        @click="handleClickDropDownChooseHeatmapType"
                        v-click-outside="handleClickOutSideDropDownChooseHeatmapType"
                        v-if="accountType === 'stores'"
                    >
                        {{ selectedHeatmapType['name'] }}
                        <span class="material-icons"> arrow_drop_down </span>
                        <div
                            ref="wrapOptionChooseHeatmapType"
                            class="wrapOption"
                        >
                            <div
                                class="Option"
                                v-for="heatmapType in listHeatmapType"
                                :key="heatmapType['type']"
                                :value="heatmapType['type']"
                                :class="[heatmapType.type === selectedHeatmapType['type'] ? 'selected' : '']"
                                @click.stop="handleClickChooseHeatmapType(heatmapType['type'])"
                            >
                                {{ heatmapType['name'] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="heatmaplayout mt-24" v-if="selectedHeatmapType['type'] === 'heatmap-layout'">
                <span v-show="!getHeatmapLayoutStatus">{{ $t('Heatmap.HeatmapLayoutNotFound') }}</span>
                <HeatmapLayout 
                    :heatmapData="heatmapLayoutData" 
                    :isDashboard="true"
                    v-show="getHeatmapLayoutStatus"
                />
            </div>
            <div class="heatmaplayout mt-24" v-if="selectedHeatmapType['type'] === 'heatmap-camera'">
                <HeatmapCameraZoneAnalysis :heatmapCameraQuery="getHeatmapCameraQuery"/>
            </div>
        </section>
        <section class="WrapContentChart mt-40">
            <div class="ContentRight">
                <div class="ChartFootFall">
                    <div class="WrapHeader">
                        <span class="titleChart mt-12">{{ $t("dashboardShopingMall.titleForGateCard") }}</span>
                        <SelectMultiChoice
                            :HandleClickOption="HandleClickOption"
                            :ShowOption="ShowOption"
                            :listZone="listZone"
                            v-click-outside="handleClickOutSideSelectZoneStatisticComeIn"
                            @select-multi-choice="handleMultiSelectZoneStatisticComeIn"
                            :title='$t("reportShopingMall.chooseZone")'
                        />
                    </div>
                    <div class="wrapChartLine">
                        <LineChartZoneAnalysis
                            :firstTimeLineChart="firstTimeLineChart"
                            :lineChartQuery="getLineChartQuery"
                        />
                    </div>
                </div>
                <div class="wrapAreaChart mt-24">
                    <div class="WrapHeader">
                        <span class="titleChart mt-12">{{ $t("dashboardShopingMall.zoneAnalysis") }}</span>
                        <SelectMultiChoice
                            :handle-click-option="handleClickSelectZoneZoneTranslate"
                            :show-option="ShowOptionArea"
                            :list-zone="listZone"
                            v-click-outside="handleClickOutSideSlectZoneZoneTranslate"
                            @select-multi-choice="handleMultiSelectZoneZoneTranslate"
                            :title='$t("reportShopingMall.chooseZone")'
                        />
                    </div>
                    <div class="wrapChartLine">
                        <StackedAreaChartTranslateZone
                            :firstTimeStackedChart="firstTimeStackedChart"
                            :stackedChartQuery="getStackedAreaChartQuery"
                            :listZoneID="listZoneID"
                        />
                    </div>

                </div>
            </div>
            <div class="ContentLeft">
                <span class="titleChart">{{ $t("dashboardShopingMall.interactionRate") }}</span>
                <div class="interaction-rate-chart-container">
                    <div class="WrapslideChart">
                        <StackedBarChartInteractionRate
                            :stackedBarChartQuery="getStackedBarChartQuery"
                        />
                    </div>
                    <div class="WrapbubleChart">
                        <QuadrantChartInteractionRate
                            :quadrantChartQuery="getStackedBarChartQuery"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import {InfoUserDialog} from "@/components";
import {
    LineChartZoneAnalysis,
    QuadrantChartInteractionRate,
    HeatmapCameraZoneAnalysis,
    StackedAreaChartTranslateZone,
    StackedBarChartInteractionRate,
    TimeSelectShopingMall,
    HeatmapLayout
} from "@/pages";
import {Api} from "@/api/index";
import * as moment from "moment-timezone";
import SelectMultiChoice from "@/pages/Components/SelectMultiChoice";
import utils from "@/utils";


const getListLayout = Api.get("listLayout")
const getListGroupHaveAnalysis = Api.get("getListGroupHaveAnalysis");
const getListZoneByGroup = Api.get("getListZoneByGroup");
const reportHeatmapLayout = Api.get("heatmapLayout");
const getListCameraModuleHeatmap = Api.get("getListCameraModuleHeatMap");

export default {
    name: "Report-ZoneAnalysis",
    components: {
        SelectMultiChoice,
        InfoUserDialog,
        TimeSelectShopingMall,
        LineChartZoneAnalysis,
        HeatmapCameraZoneAnalysis,
        StackedAreaChartTranslateZone,
        StackedBarChartInteractionRate,
        QuadrantChartInteractionRate,
        HeatmapLayout
    },
    computed: {
        getLineChartQuery() {
            return {
                dateRange: this.dateRange,
                listZoneID: this.listSelectedLineChartZoneID
            }
        },
        getStackedAreaChartQuery() {
            return {
                dateRange: this.dateRange,
                listZoneID: this.listSelectedStackedChartZoneID
            }
        },
        getStackedBarChartQuery() {
            return {
                dateRange: this.dateRange,
                groupID: this.selectedGroup["id"]
            }
        },
        getHeatmapCameraQuery() {
            return {
                dateRange: this.dateRange,
                listCamera: this.listCamera
            }
        },
        getHeatmapLayoutStatus() {
            return this.heatmapLayoutData["url"] !== undefined && this.heatmapLayoutData["url"] !== ""
        }
    },
    mounted() {
        this.accountType = localStorage.accountType

        if (this.accountType === utils.ACCOUNT_TYPE_STORES) {
            this.selectedHeatmapType = this.listHeatmapType.find(
                heatmapType => heatmapType.type === utils.HEATMAP_CAMERA
            )
        }

        getListGroupHaveAnalysis.get().then((response) => {
            this.listGroup = response.data["listAnalysis"];

            if (this.listGroup != null && this.listGroup.length > 0) {
                this.selectedGroup = this.listGroup[0]
            }

            this.getListLayoutByGroup(this.selectedGroup["id"])


            this.getListZoneByGroup(this.selectedGroup["id"])

            if (this.selectedHeatmapType.type === utils.HEATMAP_CAMERA) {
                this.getListCameraByGroup(this.selectedGroup["id"])
            }

            this.dateRange = {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            }
        });
        setTimeout(() => {
            this.timeoutSelectGate = false
        }, 1000);
    },
    data() {
        return {
            accountType: "",
            showing: false,
            ShowOption: false,
            ShowingChooseStore: false,
            ShowOptionArea: false,
            listGroup: [],
            listLayout: [],
            listZone: [],
            selectedGroup: {},
            selectedLayout: {},
            dateRange: {},
            listSelectedLineChartZoneID: "",
            listSelectedStackedChartZoneID: "",
            heatmapLayoutData: {},
            listColorToUse: [],
            listGroupName: [],
            listColor: ["#004961", "#D81F41", "#68768A", "#00CC6A", "#004961", "#FFB900", "#0063B1", "#B146C2", "#0099BC"],
            firstTimeLineChart: true,
            firstTimeStackedChart: true,
            timeoutSelectGate: true,
            isDateStartEqualDateEnd: false,
            listHeatmapType: [
                {
                    name: "Heatmap Layout",
                    type: utils.HEATMAP_LAYOUT
                },
                {
                    name: "Heatmap Camera",
                    type: utils.HEATMAP_CAMERA
                }],
            selectedHeatmapType: {
                name: "Heatmap Layout",
                type: utils.HEATMAP_LAYOUT
            },
            listCamera: []
        }
    },
    methods: {
        handleClickChooseHeatmapType(heatmapType) {
            this.handleClickOutSideDropDownChooseHeatmapType()

            this.selectedHeatmapType = this.listHeatmapType.find(heatmap => heatmap["type"] === heatmapType)
            if (this.selectedHeatmapType.type === utils.HEATMAP_LAYOUT) {
                reportHeatmapLayout.get(this.dateRange.dateStart, this.dateRange.dateEnd, this.selectedGroup["id"],
                    this.selectedLayout["id"]).then(response => {
                    this.heatmapLayoutData = response.data
                }).catch(error => {
                    if (error.response.data.message == "layout not belong to group") {
                        this.$notify({
                            timeout: 2500,
                            message: this.$t('CustomerResponse.HM0009'),
                            icon: 'add_alert',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'primary',
                        });
                    }
                })
            } else {
                getListCameraModuleHeatmap.get(this.selectedGroup["id"]).then(response => {
                    this.listCamera = response.data["listCamera"]
                })
            }

        },
        clickChoosingFloor() {
            this.$refs["wrapOption"].style.display = "block";
            this.$refs["wrapOption"].style.visibility = "visible";
        },
        clickChoosingOutFloor() {
            this.$refs["wrapOption"].style.display = "none";
            this.$refs["wrapOption"].style.visibility = "hidden";
        },
        clickChoosingLayout() {
            this.$refs["wrapOptionLayout"].style.display = "block";
            this.$refs["wrapOptionLayout"].style.visibility = "visible";
        },
        handleClickDropDownChooseHeatmapType() {
            this.$refs["wrapOptionChooseHeatmapType"].style.display = "block";
            this.$refs["wrapOptionChooseHeatmapType"].style.visibility = "visible";
        },
        handleClickOutSideDropDownChooseHeatmapType() {
            this.$refs["wrapOptionChooseHeatmapType"].style.display = "none";
            this.$refs["wrapOptionChooseHeatmapType"].style.visibility = "hidden";
        },
        clickChoosingOutLayout() {
            this.$refs["wrapOptionLayout"].style.display = "none";
            this.$refs["wrapOptionLayout"].style.visibility = "hidden";
        },
        turnShowing() {
            this.showing = !this.showing
        },
        HandleClickOption() {
            this.ShowOption = !this.ShowOption
        },
        HandleClickChooseStore() {
            this.ShowingChooseStore = !this.ShowingChooseStore
        },
        handleClickSelectZoneZoneTranslate() {
            this.ShowOptionArea = !this.ShowOptionArea
        },
        handleClickOutSideSelectZoneStatisticComeIn() {
            this.ShowOption = false
        },
        handleClickOutSideSlectZoneZoneTranslate() {
            this.ShowOptionArea = false
        },
        handleMouseOutSelectGateStackedAreaChart() {
            this.ShowOptionArea = !this.ShowOptionArea
        },
        handleSelectGroup(groupID) {
            this.selectedGroup = this.listGroup.find(group => group["id"] === groupID)
            this.getListLayoutByGroup(this.selectedGroup["id"])
            this.getListZoneByGroup(this.selectedGroup["id"])
            this.getListCameraByGroup(this.selectedGroup["id"])

            this.$refs["wrapOption"].style.display = "none";
            this.$refs["wrapOption"].style.visibility = "hidden";
        },
        handleSelectLayout(layoutID) {
            // this.clickChoosingOutLayout()
            this.selectedLayout = this.listLayout.find(layout => layout["id"] === layoutID)
            this.getReportHeatmapLayout(this.selectedGroup["id"], this.selectedLayout["id"], this.dateRange.dateStart, this.dateRange.dateEnd)
        },
        getSelectedDateRange(dateRange) {
            this.dateRange = {
                dateStart: parseInt(dateRange["dateStart"]),
                dateEnd: parseInt(dateRange["dateEnd"]),
            }

            this.isDateStartEqualDateEnd = this.dateRange.dateStart === this.dateRange.dateEnd

            this.getReportHeatmapLayout(this.selectedGroup["id"], this.selectedLayout["id"], this.dateRange.dateStart, this.dateRange.dateEnd)
        },
        handleMultiSelectZoneStatisticComeIn(listSelectedZone) {
            if (this.timeoutSelectGate === false) {
                this.firstTimeLineChart = false;
            }
            if (listSelectedZone.length === 0) {
                this.firstTimeLineChart = true
                this.listSelectedLineChartZoneID = ""
                var listSelectedLineChartZoneID = ""
                for (var i = 0; i < this.listZone.length; i++) {
                    if (i === this.listZone.length - 1) {
                        listSelectedLineChartZoneID = listSelectedLineChartZoneID + this.listZone[i].id
                    } else {
                        listSelectedLineChartZoneID = listSelectedLineChartZoneID + this.listZone[i].id + ","
                    }

                }
                this.listSelectedLineChartZoneID = listSelectedLineChartZoneID
            } else {
                this.listSelectedLineChartZoneID = ""
                this.listSelectedLineChartZoneID = listSelectedZone.join(",")
            }
        },
        handleMultiSelectZoneZoneTranslate(listSelectedZone) {
            if (this.timeoutSelectGate == false && this.firstTimeStackedChart == true) {
                this.firstTimeStackedChart = false;
            }
            if (listSelectedZone.length == 0) {

                this.listSelectedStackedChartZoneID = "a"
                var listSelectedStackedChartZoneID = ""
                for (var i = 0; i < this.listZone.length; i++) {
                    if (i == this.listZone.length - 1) {
                        listSelectedStackedChartZoneID = listSelectedStackedChartZoneID + this.listZone[i].id
                    } else {
                        listSelectedStackedChartZoneID = listSelectedStackedChartZoneID + this.listZone[i].id + ","
                    }

                }
                this.listSelectedStackedChartZoneID = listSelectedStackedChartZoneID
                this.firstTimeStackedChart = true
            } else {
                this.listSelectedStackedChartZoneID = ""
                this.listSelectedStackedChartZoneID = listSelectedZone.join(",")
            }
        },
        getListZoneByGroup(groupID) {
            getListZoneByGroup.get(groupID).then(response => {
                this.listZone = response.data.data
                for (var i = 0; i < this.listZone.length; i++) {
                    this.listGroupName.push(this.listZone[i].name)
                }
            })
        },
        getListCameraByGroup(groupID) {
            getListCameraModuleHeatmap.get(groupID).then(response => {
                this.listCamera = response.data["listCamera"]
            })
        },
        getListLayoutByGroup(groupID) {
            getListLayout.get(groupID).then(response => {
                if (response.data["listLayoutResponse"] != null) {
                    if (response.data["listLayoutResponse"].length > 0) {
                        this.listLayout = response.data["listLayoutResponse"]
                        this.selectedLayout = this.listLayout[0]
                        this.getReportHeatmapLayout(groupID, this.selectedLayout["id"], this.dateRange.dateStart, this.dateRange.dateEnd)
                    } 
                }
            })
        },
        getReportHeatmapLayout(groupID, layoutID, dateStart, dateEnd) {
            reportHeatmapLayout.get(dateStart, dateEnd, groupID, layoutID).then(response => {
                this.heatmapLayoutData = response.data
            }).catch(error => {
                if (error.response.data.message == "layout not belong to group") {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t('CustomerResponse.HM0009'),
                        icon: 'add_alert',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'primary',
                    });
                }
            })
        }
    }
}
</script>

<style lang="scss">
.custom-button-floor {
    cursor: pointer;
    padding: 9px 16px;
    display: flex;
    gap: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    line-height: 20px;
    color: #434343;
    border-radius: 8px;
    border: solid 1px #1f1f1f;
    margin-left: 16px;
    position: relative;

    &:hover {
        .wrapOption {
            visibility: visible;
        }
    }

    .wrapOption {
        background: #ffffff;
        max-height: 320px;
        overflow: auto;
        box-sizing: border-box;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        position: absolute;
        top: 40px;
        z-index: 9999;
        visibility: hidden;
        width: fit-content;

        .Option {
            padding: 16px;
            font-family: "Roboto";
            font-size: 14px;
            color: #272833;
            font-weight: 400;
            width: fit-content;
            cursor: pointer;

            &:hover {
                background-color: #f6f9ff;
            }
        }
    }
}

.wrapZoneAnalysis {
    .Header-Shopingmall {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .TitlePage {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
            color: #000000;
        }
    }

    .TimeSelect {
        height: 80px;
        display: flex;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgb(0 0 0 / 5%);
        justify-content: space-between;
        align-items: center;
        padding: 18px 24px;

        .Wraptime {
            display: flex;
            align-items: center;
        }

        .titleTime {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin-right: 10px;
        }

        .Btn-TimeSelect {
            background: #FFFFFF;
            border: 1px solid #434343;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #434343;
            position: relative;
            cursor: pointer;
        }

        .Btn-ChooseStore {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #434343;
            border: 1px solid #434343;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 12px 16px;
            height: 46px;
            cursor: pointer;
            position: relative;

            .wrapOptionChooseStore {
                background: #FFFFFF;
                box-shadow: 0px 0px 1px rgb(10 31 68 / 8%), 0px 3px 4px rgb(10 31 68 / 10%);
                border-radius: 10px;
                position: absolute;
                top: 46px;
                left: 0;
                z-index: 1;
                width: max-content;

                .Option {
                    padding: 8px 16px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    line-height: 20px;
                    width: max-content;

                    &:hover {
                        background-color: #EDF3FF;
                    }
                }
            }

        }

        .ExportReport {
            background: #FFFFFF;
            border: 1px solid #4F7DFF;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 16px;
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #4F7DFF;
            visibility: hidden;
        }

        .bbt {
            border-bottom: 1px solid #D9D9D9;
        }
    }

    .heatmapLayout {
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 24px;

        .wrapHeaderHeatmap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .titleHeatmap {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }

            .heatmap-select-container {
                display: flex;

                .display-dropdown-choose-heatmap-type {
                    display: block;
                    visibility: visible;
                }

                .hide-dropdown-choose-heatmap-type {
                    display: none;
                    visibility: hidden;
                }
            }

            .custom-button {
                color: #276EFF;
                border-color: #276EFF;

                .wrapOption {
                    width: 100%;
                    right: 0;
                    top: 44px;

                    .Option {
                        width: auto !important;
                    }
                }
            }
        }

        .heatmaplayout {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .WrapContentChart {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 528px;
        row-gap: 0;
        column-gap: 24px;

        .ContentRight {
            display: flex;
            flex-direction: column;

            .ChartFootFall {
                background-color: #FFFFFF;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                padding: 24px;

                .WrapHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .titleChart {
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #1F1F1F;
                    }

                    .Btn-ChooseGate {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 16px;
                        width: auto;
                        height: 40px;
                        border: 1px solid #276EFF;
                        box-sizing: border-box;
                        border-radius: 8px;
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #276EFF;
                        position: relative;
                        cursor: pointer;

                        .WrapOption {
                            position: absolute;
                            background: #FFFFFF;
                            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                            display: flex;
                            flex-direction: column;
                            min-width: 100%;
                            top: 40px;
                            right: 0;
                            z-index: 9;
                            padding: 16px;

                            .Option {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                padding: 16px;
                                color: #333333;
                                cursor: pointer;
                                width: max-content;
                                display: flex;
                                align-items: center;
                            }

                            .wrapOtherOption {
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                row-gap: 0;
                                column-gap: 16px;
                            }
                        }
                    }
                }

                .wrapChartLine {
                    padding-top: 12px;
                    height: 310px;
                    overflow: hidden;

                    .line-chart-zone-analysis {
                        height: 100%;

                        .columnChart {
                            height: 108%;
                        }
                    }
                }
            }

            .wrapAreaChart {
                width: 100%;
                height: 456px;
                background: #FFFFFF;
                padding: 24px;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

                .WrapHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .titleChart {
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #1F1F1F;
                    }

                    .Btn-ChooseGate {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 16px;
                        width: auto;
                        height: 40px;
                        border: 1px solid #276EFF;
                        box-sizing: border-box;
                        border-radius: 8px;
                        font-family: 'Roboto';
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #276EFF;
                        position: relative;
                        cursor: pointer;

                        .WrapOption {
                            position: absolute;
                            background: #FFFFFF;
                            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                            display: flex;
                            flex-direction: column;
                            min-width: 100%;
                            top: 40px;
                            right: 0;
                            z-index: 9;
                            padding: 16px;

                            .Option {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                padding: 16px;
                                color: #333333;
                                cursor: pointer;
                                width: max-content;
                                display: flex;
                                align-items: center;
                            }

                            .wrapOtherOption {
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                row-gap: 0;
                                column-gap: 16px;
                            }
                        }
                    }
                }

                .wrapChartLine {
                    padding-top: 12px;
                    height: 358px;
                    overflow: hidden;

                    .line-chart-zone-analysis {
                        height: 100%;

                        .columnChart {
                            height: 108%;
                        }
                    }
                }
            }
        }

        .ContentLeft {
            background: #FFFFFF;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
            padding: 36px 24px 24px 24px;
            overflow: hidden;
            margin-top: 2px;

            .titleChart {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }

            .WrapslideChart {
                overflow: hidden;
                height: 296px;

                div {
                    height: 100%;

                    .columnChart {
                        height: 109%;
                    }
                }
            }

            .WrapbubleChart {
                position: absolute;
                bottom: 24px;
                display: flex;
                width: 100%;
                justify-content: center;
            }

            .interaction-rate-chart-container {
                height: 100%;
                position: relative;
            }
        }
    }

    .sankeyChart {
        height: 533px;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        padding: 24px;

        .WrapHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .titleChart {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1F1F1F;
            }

            .Btn-ChooseGate {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 16px;
                width: auto;
                height: 40px;
                border: 1px solid #276EFF;
                box-sizing: border-box;
                border-radius: 8px;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #276EFF;
                position: relative;
                cursor: pointer;

                .WrapOption {
                    position: absolute;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    min-width: 100%;
                    top: 40px;
                    right: 0;
                    z-index: 9;
                    padding: 16px;

                    .Option {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 16px;
                        color: #333333;
                        cursor: pointer;
                        width: max-content;
                        display: flex;
                        align-items: center;
                    }

                    .wrapOtherOption {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        row-gap: 0;
                        column-gap: 16px;
                    }
                }
            }
        }
    }
}
</style>